import styled from "styled-components";
import pura from "../../../Assets/pura_black.svg";
import invest from "../../../Assets/invest_black.svg";
import gdansk from "../../../Assets/Autumn2023/Gdansk_logo.svg";
import gdansk_wolnosc from "../../../Assets/Autumn2023/Gdansk_wolnosc.svg";
import trojmiasto from "../../../Assets/trojmiasto.svg"
import prestiz from "../../../Assets/prestiz.svg"
import RCW from "../../../Assets/RCW.png"
import AB from "../../../Assets/AB.png"
import DOC from "../../../Assets/Gdansk2024/design.png";
import MAG from "../../../Assets/Gdansk2024/magazif.png";
import TER from "../../../Assets/Gdansk2024/teraz.jpg";
import SZT from "../../../Assets/Gdansk2024/sza.jpg"
import POW from "../../../Assets/Gdansk2024/wpowietrzu.png"
import UCK from "../../../Assets/Gdansk2024/uck.png"
import OB from "../../../Assets/Gdansk2024/opera.png"
import HVL from "../../../Assets/Gdansk2024/hevelianum.png"
import GUMED from "../../../Assets/Gdansk2024/gumed.png"
import GIWK from "../../../Assets/Gdansk2024/giwk.png"
import GOS from "../../../Assets/Gdansk2024/gos.png"
import Button from "../../../Components/Common/Button";
import {BUTTON_STYLE} from "../../../types/style";
import {useEffect, useState} from "react";


function GdanskPartners() {

    const [isMobile, setMobile] = useState(false);

    useEffect(() => {
        if (window.outerWidth < 1100) {
            setMobile(true);
        }
    }, []);


    return (
        <Wrapper isMobile={isMobile}>
            <SponsorsDiv>
                <Sponsors>
                    <div>
                        <h4> Organizator </h4>
                        <a href="https://www.facebook.com/Fundacja-Pura-474586729418170/">
                            <img src={pura} alt={'logo pura'}/>
                        </a>
                    </div>
                    <div>
                        <h4>Mecenat </h4>
                        <img src={invest} alt={'logo invest'}/>
                    </div>
                    <div>
                        <h4>Dofinansowano ze środków </h4>
                        <div>
                            <img src={gdansk} alt={'logo gdańsk'}/>
                            <img src={gdansk_wolnosc} alt={'gdansk wolność'}/>
                        </div>
                    </div>
                </Sponsors>
                <Button label="Zostań partnerem" onClick={() => console.log('zostan partnerem')}
                        buttonStyle={BUTTON_STYLE.PINK}/>
            </SponsorsDiv>
            <OtherLogos>
                <h4>Współpraca</h4>
                <Logos>
                    <img src={GIWK} alt={'GIWK'}/>
                    <img src={GOS} alt={'Gos'}/>
                    <img src={GUMED} alt={'GUMED'}/>
                    <img src={HVL} alt={'Hevelianum'}/>
                    <img src={OB} alt={'Opera Bałtycka'}/>
                    <img src={RCW} alt={'Regionalne centrum wolontariatu'}/>
                    <img src={UCK} alt={'UCK'}/>
                    <img src={POW} alt={'W powietrzu'}/>

                </Logos>


                <h4>Patronat Medialny</h4>
                <Logos>
                <img src={AB} alt={'Architektura Biznesu'}/>
                    <img src={DOC} alt={'design doc'}/>
                    <img src={MAG} alt={'magazif'}/>
                    <img src={prestiz} alt={'prestiz'}/>
                    <img src={SZT} alt={'sztuka architektury'}/>
                    <img src={TER} alt={'teraz'}/>
                    <img src={trojmiasto} alt={'trojmiasto'}/>
                </Logos>

            </OtherLogos>
        </Wrapper>


    );
}

export default GdanskPartners


const SponsorsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  padding: 40px 0 0 0;
  overflow-x: hidden;

  button {
    position: relative;
    top: -30px;
    left: 70vw;
  }

  h3 {
    font-family: Cabinet Grotesk, serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  h4 {
    margin: 0;
    color: #1F0812;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.16px;
    margin-bottom: 8px;
  }

  &div {
    display: flex;
    flex-direction: column;

    & div {
      flex-direction: row;
    }
  }

  img {
    min-height: 60px;
    max-height: 70px;

    & + img {
      margin-left: 40px;
    }
  }
`
const Sponsors = styled.div`
  display: flex;
  flex-direction: row;
  gap: 100px;
`
const Wrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  padding: ${({isMobile}) => (isMobile ? '12px' : ' 120px 40px 0px 40px')};
  gap: ${({isMobile}) => (isMobile ? '12px' : ' 24px')};
`

const OtherLogos = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin-bottom: 20px;
`
const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 48px;
  padding: 12px 0;
  margin-bottom: 40px;


  img {
    width: 100px;
    height: auto;
  }
`
